import React from 'react';
import Plot from 'react-plotly.js';
import { arrange_revenueDashboardData } from '../../helper/Utils';

class MultiLineChart extends React.Component {
  render() {
    const {revenueData}=this.props;
    const data=arrange_revenueDashboardData(revenueData)
    // const data = [
    //   {
    //     type: 'scatter',
    //     mode: 'lines',
    //     name: 'GS SCORE - DELHI',
    //     x: [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    //     y: [1000,2000,4000,4000,1000,17000,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //   },
    //   {
    //     type: 'scatter',
    //     mode: 'lines',
    //     name: 'GS SCORE - JAMMU',
    //     x: [1, 2, 3, 4, 5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30],
    //     y: [2000,3000,5000,1000,6000,13000,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    //   },
    //   // Add more trace objects for additional lines
    // ];

    const layout = {
      title: 'REVENUE GENERATED PER DAY',
      xaxis: {
        title: 'DATE',
      },
      yaxis: {
        title: 'AMOUNT',
      },
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
}
export default MultiLineChart;
