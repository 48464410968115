import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

import { all_center_list, totalRevenueDashboard } from "../helper/Utils";
import MultiLineChart from "../components/Chart/MultiLineChart";

import { COPYTRACKING_LIST_API, NUMBER_OF_ENROLLED_STUDENT_COURSES_API, NUMBER_OF_ENROLLED_STUDENT_MODE_WISE_API, NUMBER_OF_EVENTS_TEACHER_WISE_API, NUMBER_OF_RUNNING_COURSES_API, REVENUE_COURSES_API} from "../helper/Iasscore_api";
import RunningCoursesChart from "../components/Bar/RunningCoursesChart";
import EnrolledStudentPie from "../components/Pie/EnrolledStudentPie";
import EnrolledStudentModeWise from "../components/Bar/EnrolledStudentModeWise";
import TeacherScheduleEvent from "../components/Bar/TeacherScheduleEvent";
import { ApiLoader } from "../components/Spinner/Spinner";
import CopyTrackingChart from "../components/Bar/CopyTrackingChart";

export default function FinanceMonitoring() {
  let center_list = all_center_list();
  
  let currentDate = new Date(); // current date and time
  const max_date = currentDate.toISOString().split('T')[0];
  currentDate.setDate(currentDate.getDate() - 2);
  const min_date = currentDate.toISOString().split('T')[0];

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [revenue_filter, setRevenueFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })
  
  const [runningCourseFilter, setRunningCourseFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centres: null
  })

  const [enrolledStudentFilter, setEnrolledStudentFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  const [enrolledStudentModeFilter, setEnrolledStudentModeFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  const [teacherEventFilter, setTeacherEventFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  const [copyTrackingFilter, setcopyTrackingFilter] = useState({
    start_date: min_date,
    end_date: max_date,
    centre_id: ''
  })

  // data store state
  const [revenueData, setRevenueData] = useState([]);
  const [runningCourseData, setRunningCourseData] = useState([]);
  const [enrolledStudentData, setEnrolledStudentData] = useState([]);
  const [enrolledStudentModeData, setEnrolledStudentModeData] = useState([]);
  const [teacherEventData, setTeacherEventData] = useState([]);
  const [copyTrackingData, setCopyTrackingData] = useState([]);
  //loader state
  const [revenueLoading,setRevenueLoading]=useState(false);
  const [runningCourseLoading,setRunningCourseLoading]=useState(false);
  const [enrlStdLoading,setEnrlStdLoading]=useState(false);
  const [stdModeLoading,setStdModeLoading]=useState(false);
  const [scheduleEventLoading,setScheduleEventLoading]=useState(false);
  const [copyTrackingLoading,setCopyTrackingLoading]=useState(false);

  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');

      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  const get_revenue_courses = async () => {
    try {
      setRevenueLoading(true);
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await REVENUE_COURSES_API(revenue_filter, config);
      setRevenueLoading(false);
      if (data.status) {        
        setRevenueData(data.list)
      } else {
        setRevenueData([])
      }
    } catch (e) {
      setRevenueLoading(false);
      setRevenueData([])
      console.log(e);
    }
  }

  const get_running_courses = async () => {
    setRunningCourseLoading(true);
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await NUMBER_OF_RUNNING_COURSES_API(runningCourseFilter, config);
      setRunningCourseLoading(false);
      if (data.status) {
        setRunningCourseData(data.list)
      } else {
        setRunningCourseData([])
      }
    } catch (e) {
      setRunningCourseLoading(false);
      setRunningCourseData([])
      console.log(e);
    }
  }

  const get_enrolled_students = async () => {
    setEnrlStdLoading(true)
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await NUMBER_OF_ENROLLED_STUDENT_COURSES_API(enrolledStudentFilter, config);
      setEnrlStdLoading(false)
      if (data.status) {
        setEnrolledStudentData(data.list)
      } else {
        setEnrolledStudentData([])
      }
    } catch (e) {
      setEnrlStdLoading(false)
      setEnrolledStudentData([])
      console.log(e);
    }
  }

  const get_enrolled_students_mode_wise = async () => {
    setStdModeLoading(true)
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await NUMBER_OF_ENROLLED_STUDENT_MODE_WISE_API(enrolledStudentModeFilter, config);
      setStdModeLoading(false)
      if (data.status) {
        setEnrolledStudentModeData(data.list)
      } else {
        setEnrolledStudentModeData([])
      }
    } catch (e) {
      setStdModeLoading(false)
      setEnrolledStudentModeData([])
      console.log(e);
    }
  }

  const get_events_teacher_wise = async () => {
    setScheduleEventLoading(true);
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await NUMBER_OF_EVENTS_TEACHER_WISE_API(teacherEventFilter, config);
      setScheduleEventLoading(false);
      if (data.status) {
        setTeacherEventData(data.list)
      } else {
        setTeacherEventData([])
      }
    } catch (e) {
      setScheduleEventLoading(false);
      setTeacherEventData([])
      console.log(e);
    }
  }

  const get_copytracking_list = async () => {
    setCopyTrackingLoading(true)
    try {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      const { data } = await COPYTRACKING_LIST_API(copyTrackingFilter, config);
      setCopyTrackingLoading(false)
      if (data.status) {
        setCopyTrackingData(data.list)
      } else {
        setCopyTrackingData([])
      }
    } catch (e) {
      setCopyTrackingLoading(false)
      setCopyTrackingData([])
      console.log(e);
    }
  }

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    get_revenue_courses();
  }, [revenue_filter])

  useEffect(() => {
    get_running_courses();
  }, [runningCourseFilter])

  useEffect(() => {
    get_enrolled_students();
  }, [enrolledStudentFilter])

  useEffect(() => {
    get_enrolled_students_mode_wise();
  }, [enrolledStudentModeFilter])

  useEffect(() => {
    get_events_teacher_wise();
  }, [teacherEventFilter])

  useEffect(() => {
    get_copytracking_list();
  }, [copyTrackingFilter])

  const handleRevenueFilterchange = (e) => {
    const { name, value } = e.target;
    setRevenueFilter({ ...revenue_filter, [name]: value })
  }

  const handleRunningCourseFilterchange=(e)=>{
    const { name, value } = e.target;
    setRunningCourseFilter({ ...runningCourseFilter, [name]: value })
  }

  const handleEnrolledStudentFilterchange=(e)=>{
    const { name, value } = e.target;
    setEnrolledStudentFilter({ ...enrolledStudentFilter, [name]: value })
  }

  const handleEnrolledStudentModeFilterchange=(e)=>{
    const { name, value } = e.target;
    setEnrolledStudentModeFilter({ ...enrolledStudentModeFilter, [name]: value })
  }

  const handleTeacherEventFilterchange=(e)=>{
    const { name, value } = e.target;
    setTeacherEventFilter({ ...teacherEventFilter, [name]: value })
  }

  const handleCopyTrackingFilterchange=(e)=>{
    const { name, value } = e.target;
    setcopyTrackingFilter({ ...copyTrackingFilter, [name]: value })
  }

  const get_enrolledStudentPercent=(current)=>{    
    let total=0;
    enrolledStudentData.forEach(li => {
        const {total_admissions,product_category}=li;
        
        total+=parseInt(total_admissions)
    });
    let percent=(current/total)*100;
    return percent;
  }

  const total_running_course=()=>{
    let total=0;
    runningCourseData.forEach(li => {
      const {total_packages}=li;
        total+=parseInt(total_packages)
    });
    return total;
  }

  const total_enrolled_student=()=>{
    let total=0;
    enrolledStudentData.forEach(li => {
        const {total_admissions}=li;
        total+=parseInt(total_admissions)
    });
    return total;
  }

  const total_enrolled_student_mode_wise=()=>{
    let total=0;
    let total_online=0;
    let total_offline=0;
    enrolledStudentModeData.forEach(li => {
        const {online_student,offline_student}=li;
        total_online+=parseInt(online_student)
        total_offline+=parseInt(offline_student)
    });
    total=total_online+total_offline;
    let arr={
      total,
      total_online,
      total_offline,
    };
    return arr;
  }
  const totalEnrolledStudents = total_enrolled_student_mode_wise();


  const total_teacher_event=()=>{
    let total=0;
    let total_classes=0,total_discussion=0,total_activity=0
    teacherEventData.forEach(li => {
        const {classes,discussion,activity}=li;
        total_classes+=parseInt(classes)
        total_discussion+=parseInt(discussion)
        total_activity+=parseInt(activity)
    });
    total=total_classes+total_discussion+total_activity
    let result={
      total_classes,
      total_discussion,
      total_activity,
      total
    }
    return result;
  }


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Finance Monitoring</li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Academic Dashboard</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>
        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Container>
                  <Row>
                    <Col xl="4">
                      <h1 className="finance-value"><p className="fs-sm fw-medium mb-2 mb-xl-1">Total Revenue</p><span><b>&#8377;</b></span>{totalRevenueDashboard(revenueData).toLocaleString()} </h1>
                    </Col>
                    <Col xl="8">
                      <Row>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label>From:</Form.Label>
                            <Form.Control type="date" placeholder="" max={max_date} value={revenue_filter.start_date} name="start_date" onChange={handleRevenueFilterchange} />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label>End:</Form.Label>
                            <Form.Control type="date" placeholder="" max={max_date} value={revenue_filter.end_date} name="end_date" onChange={handleRevenueFilterchange} />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Label>Center:</Form.Label>
                            <Form.Select name="centre_id" onChange={handleRevenueFilterchange} value={revenue_filter.centre_id}>
                              <option value="">All Center</option>
                              {
                                center_list.map((center) => {
                                  return (
                                    <option key={center.center_id} value={center.center_id}>{center.center_name}</option>
                                  );
                                })
                              }
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </Card.Header>
              <Card.Body className="overflow-hidden px-0 pb-3">
              {revenueLoading ? 
                <div className="text-center">
                  <ApiLoader color="danger" text="Loading"/>
                </div>
                :<MultiLineChart revenueData={revenueData} />
              } 
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Enrolled Student <span className="text-danger">({total_enrolled_student()})</span> : </Card.Title>
                
              </Card.Header>
              <Card.Body className="p-2 pt-1">
                <Row>
                  <Col md="6">
                    <Row>
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>From:</Form.Label>
                          <Form.Control type="date" placeholder="" value={enrolledStudentFilter.start_date} name="start_date" onChange={handleEnrolledStudentFilterchange} max={max_date} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>End:</Form.Label>
                          <Form.Control type="date" placeholder=""  value={enrolledStudentFilter.end_date} name="end_date" onChange={handleEnrolledStudentFilterchange} max={max_date} />
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className="mb-3" controlId="">
                          <Form.Label>Center:</Form.Label>
                          <Form.Select name="centre_id" onChange={handleEnrolledStudentFilterchange}>
                            <option value="">All Center</option>
                            {
                              center_list.map((center) => {
                                return (
                                  <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                                );
                              })
                            }
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>
                    {enrlStdLoading ? 
                    <div className="text-center">
                      <ApiLoader color="danger" text="Loading"/>
                    </div>
                    :<EnrolledStudentPie enrolledStudentData={enrolledStudentData} />
                    }
                  </Col>
                  <Col md="6">
                    <div className="enrolledStudentData">
                      <Row className="g-4 mb-2">
                        {enrolledStudentData.map((item, index) => (
                          <Col xs="4" key={index}>
                            <label className="fs-sm fw-medium mb-1">{item.product_category}</label>
                            <h3 className="card-value mb-2">{item.total_admissions} <small>
                              {item.product_category!=='PT Test'?get_enrolledStudentPercent(item.total_admissions).toFixed(1)+"%":''}</small>
                              </h3>
                            <ProgressBar now={get_enrolledStudentPercent(item.total_admissions)} className="ht-5 mb-0" variant="ui-02" />
                          </Col>
                        ))}
                      </Row>                      
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
      
          <Col xl="6">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">New Batch Started Category Wise <span className="text-danger">({total_running_course()})</span> : </Card.Title>
                
              </Card.Header>
              <Card.Body className="p-2 pt-1">
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="date" placeholder="" value={runningCourseFilter.start_date} name="start_date" onChange={handleRunningCourseFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>End:</Form.Label>
                      <Form.Control type="date" placeholder=""  value={runningCourseFilter.end_date} name="end_date" onChange={handleRunningCourseFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Center:</Form.Label>
                      <Form.Select name="centres" onChange={handleRunningCourseFilterchange}>
                        <option value="">All Center</option>
                        {
                          center_list.map((center) => {
                            // console.log(center);
                            return (
                              <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                            );
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {runningCourseLoading ? 
                <div className="text-center">
                  <ApiLoader color="danger" text="Loading"/>
                </div>
                :<RunningCoursesChart runningCourseData={runningCourseData}/>
                }
              </Card.Body>
            </Card>
          </Col>
          <Col xl="6">
            <Card className="card-one">
              
              <Card.Header>
                <Card.Title as="h6">Enrolled Student (Mode Wise) <span className="text-danger">({`Online:${totalEnrolledStudents.total_online}, Offline:${totalEnrolledStudents.total_offline}, Total:${totalEnrolledStudents.total}`})</span> : </Card.Title>
                
              </Card.Header>
              <Card.Body className="p-2 pt-1">
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="date" placeholder="" value={enrolledStudentModeFilter.start_date} name="start_date" onChange={handleEnrolledStudentModeFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>End:</Form.Label>
                      <Form.Control type="date" placeholder=""  value={enrolledStudentModeFilter.end_date} name="end_date" onChange={handleEnrolledStudentModeFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Center:</Form.Label>
                      <Form.Select name="centre_id" onChange={handleEnrolledStudentModeFilterchange}>
                        <option value="">All Center</option>
                        {
                          center_list.map((center) => {
                            // console.log(center);
                            return (
                              <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                            );
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {stdModeLoading ? 
                <div className="text-center">
                  <ApiLoader color="danger" text="Loading"/>
                </div>
                :<EnrolledStudentModeWise enrolledStudentModeData={enrolledStudentModeData}/>
                }                
              </Card.Body>
            </Card>
          </Col>
          <Col xl="6">
            <Card className="card-one">
              
              <Card.Header>
                <Card.Title as="h6">Copy Tracking : </Card.Title>
                
              </Card.Header>
              <Card.Body className="p-2 pt-1">
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="date" placeholder="" value={copyTrackingFilter.start_date} name="start_date" onChange={handleCopyTrackingFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>End:</Form.Label>
                      <Form.Control type="date" placeholder=""  value={copyTrackingFilter.end_date} name="end_date" onChange={handleCopyTrackingFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Center:</Form.Label>
                      <Form.Select name="centre_id" onChange={handleCopyTrackingFilterchange}>
                        <option value="">All Center</option>
                        {
                          center_list.map((center) => {
                            // console.log(center);
                            return (
                              <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                            );
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {copyTrackingLoading ? 
                <div className="text-center">
                  <ApiLoader color="danger" text="Loading"/>
                </div>
                :<CopyTrackingChart copyTrackingData={copyTrackingData}/>
                }                
              </Card.Body>
            </Card>
          </Col>
          <Col xl="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Teacher Schedule Events : <span className="text-danger">(Classes:{total_teacher_event().total_classes}, Discussion:{total_teacher_event().total_discussion},  Activity: {total_teacher_event().total_activity}): <span className="text-dark">
                Total  {total_teacher_event().total}</span>
                </span> : 
                </Card.Title>
                
              </Card.Header>
              <Card.Body className="p-2 pt-1">
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>From:</Form.Label>
                      <Form.Control type="date" placeholder="" value={teacherEventFilter.start_date} name="start_date" onChange={handleTeacherEventFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>End:</Form.Label>
                      <Form.Control type="date" placeholder=""  value={teacherEventFilter.end_date} name="end_date" onChange={handleTeacherEventFilterchange} max={max_date} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3" controlId="">
                      <Form.Label>Center:</Form.Label>
                      <Form.Select name="centre_id" onChange={handleTeacherEventFilterchange}>
                        <option value="">All Center</option>
                        {
                          center_list.map((center) => {
                            // console.log(center);
                            return (
                              <option value={center.center_id} key={center.center_id}>{center.center_name}</option>
                            );
                          })
                        }
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                {scheduleEventLoading ? 
                  <div className="text-center">
                    <ApiLoader color="danger" text="Loading"/>
                  </div>
                  :<TeacherScheduleEvent teacherEventData={teacherEventData}/>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
