import React, { useState, useEffect } from "react";
import { Button, Card, Col, Row, Table, Collapse, Container, FormControl, InputGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import "../assets/css/packages-calender.css";
import { packages_and_programs } from "../helper/Api";
import Pagination from "./Pagination"; // Import the Pagination component

function createData(id, title, paidStudents, unpaidStudents, batch_start, valid_till, programs) {
  return {
    id,
    title,
    paidStudents,
    unpaidStudents,
    batch_start,
    valid_till,
    programs: programs?.map(program => ({
      title: program.title,
      batch_start: program.batch_start,
      program_code: program.program_code,
      course_hours: program.course_hours
    }))
  };
}

function RowComponent({ row, index }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{row.title}</td>
        <td>{row.paidStudents}</td>
        <td>{row.unpaidStudents}</td>
        <td className="text-right">{row.batch_start}</td>
        <td className="text-right">{row.valid_till}</td>
        <td>
          <Button
            variant="primary"
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            {open ? 'Hide Programs' : 'Show Programs'}
          </Button>
        </td>
      </tr>
      <tr>
        <td colSpan="5" style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open}>
            <div>
              <Container fluid>
                <Row>
                  <Col>
                    <h6>Programs</h6>
                    <Table size="sm" aria-label="programs">
                      <thead>
                        <tr>
                          <th>S.no.</th>
                          <th>Title</th>
                          <th>Program Start Date</th>
                          <th className="text-right">Course Hours</th>
                          <th className="text-right">Program Code</th>
                        </tr>
                      </thead>
                      <tbody>
                        {row.programs?.map((program, index) => (
                          <tr key={program.program_code}>
                            <td>{index + 1}</td>
                            <td>{program.title}</td>
                            <td>{program.batch_start ? new Date(program.batch_start).toLocaleDateString() : 'N/A'}</td>
                            <td className="text-right">{program.course_hours}</td>
                            <td className="text-right">{program.program_code}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  );
}

export default function PackagesAndPrograms() {
  const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [data_packages, setData_packages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const itemsPerPage = 25;

  const switchSkin = (skin) => {
    const btnClass = skin === 'dark' ? 'btn-outline-primary' : 'btn-white';
    const btnToUpdate = document.getElementsByClassName(skin === 'dark' ? 'btn-white' : 'btn-outline-primary');
    for (const btn of btnToUpdate) {
      btn.classList.add(btnClass);
      btn.classList.remove(skin === 'dark' ? 'btn-white' : 'btn-outline-primary');
    }
  };

  const get_packages_and_programs = async () => {
    const { data } = await packages_and_programs();
    setData_packages(data);
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    get_packages_and_programs();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };


  const filteredData = data_packages.filter(pkg => {
    const batchStartDate = new Date(pkg.batch_start);
    const start = new Date(startDate);
    const end = new Date(endDate);

    const isWithinDateRange = (!startDate || !endDate || (batchStartDate >= start && batchStartDate <= end));
    return pkg.title.toLowerCase().includes(searchTerm.toLowerCase()) && isWithinDateRange;
  });

  // Sort the filtered data from latest to oldest based on batch_start date
  filteredData.sort((a, b) => new Date(b.batch_start) - new Date(a.batch_start));

  // Calculate the indices for slicing the data
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);


  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Packages & Programs</li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Packages & Programs</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button variant="" className="btn-white d-flex align-items-center gap-2">
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            <Button variant="primary" className="d-flex align-items-center gap-2">
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>
        <Row className="g-3 mb-4 align-items-center">
  <Col xl="8">
    <InputGroup className="mb-3" style={{ maxWidth: '400px' }}>
      <FormControl
        placeholder="Search by title"
        aria-label="Search by title"
        aria-describedby="basic-addon2"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <InputGroup.Text id="basic-addon2">
        <FaSearch />
      </InputGroup.Text>
    </InputGroup>
  </Col>
  <Col xl="2">
    <Form.Group className="mb-3">
      <Form.Control
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={{ maxWidth: '150px' }}
      />
    </Form.Group>
  </Col>
  <Col xl="2">
    <Form.Group className="mb-3">
      <Form.Control
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={{ maxWidth: '150px' }}
      />
    </Form.Group>
  </Col>
</Row>

        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one p-3">
              <Card.Body className="overflow-hidden px-0 pb-3">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th> 
                      <th>Paid Student</th> 
                      <th>Unpaid Student</th> 
                      <th className="text-right">Start Date</th>
                      <th className="text-right">Valid Till</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((row, index) => (
                      <RowComponent key={row.id} row={row} index={index} />
                    ))}
                  </tbody>
                </Table>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={handlePageChange}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Footer />
      </div>
    </React.Fragment>
  );
}
