import axios from "axios";

// const URL=process.env.REACT_APP_BACKEND_URL;
// const API=axios.create({baseURL:'http://127.0.0.1:8000/api/'});
const API=axios.create({baseURL:'https://masterdashboardapi.basix.in/api/'});

// AUTHENTICATION
export const AUTH_LOGIN_API = (FormData)=>API.post('accounts/user/login/',FormData);


// MANAGE  USER 
export const ADD_USER_API = (FormData,config)=>API.post('users/',FormData,config);
export const GET_USERS_API = (FormData,config)=>API.get('user',FormData,config);

// MANAGE TASK 


// company utils
export const company_list_api = ()=>API.get('company/company');
export const department_list_api = (company_id,config)=>API.get(`company/department/${company_id}`,config);
export const position_list_api = (config)=>API.get('company/position',config);

// package calender events 
export const calender_events_api = ()=>API.get('packageList/');
export const packages_and_programs = ()=>API.get('detailsList/');
export const student_api = ()=>API.get('detailsUser/');

