import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

export const Loader_spin = (color,text) => {
  return (
    <div>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          variant={color}
        />
        {text? " "+text:''}
    </div>
  )
}

export const ApiLoader = ({color,text}) => {
  return (
    <div>
        <Spinner
          as="span"
          animation="border"
          size="lg"
          role="status"
          aria-hidden="true"
          variant={color}
        />
        <p>
        {text? " "+text:''}
        </p>
    </div>
  )
}

export const GrowSpinner=()=>{
    return (
        <>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        Loading...
        </>
    );
}
