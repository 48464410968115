import React from 'react';
import Plot from 'react-plotly.js';
import {arrangeSourceOpprData } from '../../helper/Utils';

class SourceOppr extends React.Component {
  render() {
    const {sourceOpprData}=this.props;
    const trace1=arrangeSourceOpprData(sourceOpprData)
    const data=[trace1];
    const layout = {
      // title: 'Enrolled Student (Mode Wise)',
      xaxis: {
        title: 'Source Type',
      },
      yaxis: {
        title: 'Number of Opportunity',
      },
      height:400,
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
      />
    );
  }
}
export default SourceOppr;
