import React from 'react';
import Plot from 'react-plotly.js';
import {arrangeCounsellorOpprData } from '../../helper/Utils';

class CounsellorOpportunity extends React.Component {
  render() {
    const {counsellorOpprData}=this.props;
    // console.log(counsellorLeadData);
    const trace1=arrangeCounsellorOpprData(counsellorOpprData)
    const data=[trace1];
    const layout = {
      // title: 'Enrolled Student (Mode Wise)',
      xaxis: {
        title: 'Counsellor',
      },
      yaxis: {
        title: 'Number of Opportunity',
      },
      height:400,
      // barmode: 'group'
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
      />
    );
  }
}
export default CounsellorOpportunity;
