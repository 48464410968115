import { toast } from "react-toastify";


const successToast = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

const errorToast = (message) => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};

const warningToast = (message) => {
  toast.warn(message, {
    position: toast.POSITION.TOP_CENTER,
  });
};


export {successToast,errorToast,warningToast};
