import React, { useEffect, useState } from 'react';
// import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Button, Col, Row, Form, Card, } from "react-bootstrap";
import { Link } from 'react-router-dom';
// import trash from '../../assets/svg/trash-2.svg';
import { department_list_api, position_list_api } from '../../helper/Api';
import { useSelector } from 'react-redux';
import {TiDelete} from 'react-icons/ti';


const AddRole = () => {
  const {authData}=useSelector((state)=>state.authData);

  const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
  const [skin, setSkin] = useState(currentSkin);
  const [roles,setRoles]=useState([{
    "department":null,
    "position":null
  }]);
  const [departments,setDepartments]=useState([]);
  const [positions,setPositions]=useState([]);
  const switchSkin = (skin) => {
    if (skin === 'dark') {
      const btnWhite = document.getElementsByClassName('btn-white');
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(()=>{
    get_company_and_position()
  },[]);
  
  const handleAddMore = () => {
    setRoles([...roles, { department: null, position: null }]);
  };

  const get_company_and_position = async() =>{
    const config = {
      headers: {
        'Authorization': `Bearer ${authData.token.access}`,
      },
    };
    try {      
      const {data}=await department_list_api(authData.company.uid,config);
      if(data.status){
        setDepartments(data.list)
      }else{
        setDepartments([])
      }
    } catch (error) {
      setDepartments([])
    }
    try {
      const {data}=await position_list_api(config);
      if(data.status){
        setPositions(data.list)
      }else{
        setPositions([])
      }
    } catch (error) {
      setPositions([])
    }
  }
  

  const handleDeleteRow  = (i) => {
    const updatedRoles = [...roles];
    updatedRoles.splice(i, 1);
    setRoles(updatedRoles);
  }
  // Function to update department or position for a specific row
  const handleRowChange = (index, field, value) => {
    const updatedRoles = [...roles];
    updatedRoles[index][field] = value;
    setRoles(updatedRoles);
  };

  const handleSubmit=(e)=>{
    e.preventDefault();
    console.log(roles);
  }
  return (
    <>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Add User Role</li>
            </ol>
            <h4 className="main-title mb-0">Add User Role</h4>
          </div>
        </div>
        <Row className="g-3">
          <Col xl="12">
            <Card className="card-one">
              <Card.Body className="overflow-hidden px-0 pb-3">
                <div className="p-3 p-xl-4">
                  <Form onSubmit={handleSubmit}>
                    {
                      roles.map((role,i)=>{
                        return (
                          <Row key={i}>
                            <Col sm="5" xs="6" className="mt-4" >
                              <Form.Select onChange={(e) => handleRowChange(i, 'department', e.target.value)}
                              value={role.department || ''}
                              required
                              >
                                <option value=''>Select Department</option>
                                {
                                  departments.map((dept)=>{
                                    return (
                                      <option value={dept.uid} key={dept.uid}>{dept.name}</option>
                                    )
                                  })
                                }                                
                              </Form.Select>
                            </Col>
                            <Col sm="5" xs="6" className="mt-4" >
                              <Form.Select onChange={(e) => handleRowChange(i, 'position', e.target.value)}
                              value={role.position || ''}
                              required
                              >
                                <option value=''>Select Position</option>
                                {
                                  positions.map((position)=>{
                                    return (
                                      <option value={position.uid} key={position.uid}>{position.name}</option>
                                    )
                                  })
                                } 
                              </Form.Select>
                            </Col>
                            {i>0?
                            <>
                              <Col sm="2" xs="12" className="d-flex align-items-center justify-content-center">
                                <div className="mt-4">
                                  <Button variant="danger" onClick={()=>handleDeleteRow(i)} className='btn btn-sm'>
                                    <TiDelete size={20} title="Delete"  />
                                  </Button>
                                </div>
                              </Col>
                            </>
                            :
                            <>
                            <Col sm="2" xs="12" className="d-flex align-items-center justify-content-center">
                              <div className="mt-4">
                                <Button variant="primary" onClick={handleAddMore} className='btn btn-sm'><b><i className="ri-add-line"></i></b></Button>
                              </div>
                            </Col>
                            </>
                            }                            
                          </Row>
                        );
                      })
                    }   
                    <Row className="mt-4 text-center">
                      <Col sm="10" xs="12">
                        <Button variant="primary" type='submit'> Submit</Button>
                      </Col>
                      <Col sm="2" xs="12"></Col>
                    </Row>                 
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>


  )
}

export default AddRole
