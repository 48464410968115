import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table, Pagination, FormControl, InputGroup } from "react-bootstrap";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import { Link } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { student_api } from "../helper/Api";

export default function StudentInfo() {
    const [rows, setRows] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchQuery, setSearchQuery] = useState("");

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'fullname', headerName: 'Name', width: 200 },
        { field: 'email', headerName: 'Email ID', width: 280 },
        { field: 'contact', headerName: 'Contact No.', width: 120 },
        { field: 'address', headerName: 'Address', width: 450 },
        { field: 'infoBTN', headerName: '', width: 80 },
    ];

    const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');
            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    };

    const getCalenderEvents = async () => {
        try {
            const { data } = await student_api();
            const transformedData = data.map((item, index) => ({
                id: index + 1,
                fullname: item.fullname,
                email: item.email,
                contact: item.contact,
                address: item.address,
                infoBTN: <a className="btn btn-primary btn-sm" href="#">INFO</a>
            }));
            setRows(transformedData);
        } catch (e) {
            console.log(e);
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    useEffect(() => {
        getCalenderEvents();
    }, []);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value?.toLowerCase());
        setCurrentPage(1); // Reset to the first page on new search
    };

    // Filter rows based on search query
    const filteredRows = rows.filter((row) => {
        return (
            row.fullname?.toLowerCase().includes(searchQuery) ||
            row.email?.toLowerCase().includes(searchQuery) ||
            row.contact?.toLowerCase().includes(searchQuery)
        );
    });

    // Calculate pagination
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = filteredRows.slice(indexOfFirstRow, indexOfLastRow);

    const totalPages = Math.ceil(filteredRows.length / rowsPerPage);

    return (
        <React.Fragment>
            <Header onSkin={setSkin} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Student Information</li>
                        </ol>
                        <h4 className="main-title mb-0">Welcome to Student Information Table</h4>
                    </div>
                    <div className="d-flex gap-2 mt-3 mt-md-0">
                        <Button variant="" className="btn-white d-flex align-items-center gap-2">
                            <i className="ri-share-line fs-18 lh-1"></i>Share
                        </Button>
                        <Button variant="" className="btn-white d-flex align-items-center gap-2">
                            <i className="ri-printer-line fs-18 lh-1"></i>Print
                        </Button>
                        <Button variant="primary" className="d-flex align-items-center gap-2">
                            <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate<span className="d-none d-sm-inline"> Report</span>
                        </Button>
                    </div>
                </div>
                <Row className="g-3">
                    <Col xl="12">
                        <Card className="card-one p-3">
                            <Card.Body className="overflow-hidden px-0 pb-3">
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>
                                        <FaSearch />
                                    </InputGroup.Text>
                                    <FormControl
                                        placeholder="Search by Name, Email, or Contact"
                                        onChange={handleSearchChange}
                                    />
                                </InputGroup>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            {columns.map((col) => (
                                                <th key={col.field} style={{ width: col.width }}>{col.headerName}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentRows.map((row) => (
                                            <tr key={row.id}>
                                                {columns.map((col) => (
                                                    <td key={col.field}>{row[col.field]}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <Pagination>
                                    <Pagination.Prev
                                        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
                                    />
                                    {[...Array(totalPages)].map((_, index) => (
                                        <Pagination.Item
                                            key={index + 1}
                                            active={index + 1 === currentPage}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)}
                                    />
                                </Pagination>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Footer />
            </div>
        </React.Fragment>
    );
}
