import React, { useEffect, useState } from 'react';
// import HeaderMobile from '../../layouts/HeaderMobile';
import Header from '../../layouts/Header';
import { Button, Col, Row, Form, Card, } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { ADD_USER_API } from '../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { errorToast, successToast } from '../../helper/ToastNotification';
import { ADD_USER_START,ADD_USER_SUCCESS,ADD_USER_FAIL } from '../../RTK/Slices/UserSlice';
import { Loader_spin } from '../../components/Spinner/Spinner';

const AddUser = () => {
    const {authData}=useSelector((state)=>state.authData);
    const {loading}=useSelector((state)=>state.user);
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const [formData,setFormData]=useState({
      "first_name":"",
      "last_name":"",
      "email":"",
      "mobile":"",
      "password":"",
      "password2":"",
    })

    const [errors,setErrors]=useState({});
    const dispatch=useDispatch();
    const switchSkin = (skin) => {
        if (skin === 'dark') {
          const btnWhite = document.getElementsByClassName('btn-white');
          for (const btn of btnWhite) {
            btn.classList.add('btn-outline-primary');
            btn.classList.remove('btn-white');
          }
        } else {
          const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
          for (const btn of btnOutlinePrimary) {
            btn.classList.remove('btn-outline-primary');
            btn.classList.add('btn-white');
          }
        }
      };
    switchSkin(skin);
    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const handleChange=(e)=>{
      const {name,value}=e.target;
      setFormData({...formData,[name]:value})
    }

    const handleSubmit=async(e)=>{
      dispatch(ADD_USER_START())
      const config = {
        headers: {
          'Authorization': `Bearer ${authData.token.access}`,
        },
      };
      e.preventDefault();
      try {
        const {data}=await ADD_USER_API(formData,config);
        if(data.status){
          successToast(data.message)
          dispatch(ADD_USER_SUCCESS(data.user))
        }else{
          dispatch(ADD_USER_FAIL(data.message))
          setErrors(data.message)
          if(data.message.non_field_errors){
            errorToast(data.message.non_field_errors[0])
          }
        }
      } catch (error) {
        const {response}=error; 
        console.log(response)    
      }
    }

  return (
    <div>
      <Header onSkin={setSkin} />
      
      <div className="main main-app p-3 p-lg-4">
      < div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Add User</li>
            </ol>
            <h4 className="main-title mb-0">Add User</h4>
          </div>
        </div>
        <div className="mb-4">
             <div className="mb-3">
              <Card className="p-4">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="First_Name">First Name <span className="text-danger">*</span></Form.Label>
                      <Form.Control type="Text" id="First_Name" name='first_name' placeholder="First Name" onChange={handleChange} />
                      <span className='text-danger'>{errors.first_name?errors.first_name[0]:""}</span>
                    </Col>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="Last_Name">Last Name </Form.Label>
                      <Form.Control type="Text" id="Last_Name" name='last_name' placeholder="Last Name" onChange={handleChange} />
                    </Col>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="Email">Email<span className="text-danger">*</span></Form.Label>
                      <Form.Control type="email" id="Email" name='email' placeholder="Email" onChange={handleChange} />
                      <span className='text-danger'>{errors.email?errors.email[0]:""}</span>
                    </Col>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="Number">Mobile Number <span className="text-danger">*</span></Form.Label>
                      <Form.Control type="Number" id="Number" name='mobile' placeholder="Mobile Number" onChange={handleChange} />
                      <span className='text-danger'>{errors.mobile?errors.mobile[0]:""}</span>
                    </Col>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="Password">Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control type="Password" id="Password" name='password' placeholder="Password" onChange={handleChange} />
                      <span className='text-danger'>{errors.password?errors.password[0]:""}</span>

                    </Col>
                    <Col sm="6" className="mb-4">
                      <Form.Label htmlFor="Password2">Confirm Password <span className="text-danger">*</span></Form.Label>
                      <Form.Control type="Password" id="Password2" name='password2' placeholder="Password" onChange={handleChange} />
                      <span className='text-danger'>{errors.password2?errors.password2[0]:""}</span>
                    </Col>
                  </Row>
                  <div className="d-flex align-items-center justify-content-center">
                  <Button type={loading?"button":'submit'} variant="primary" className="btn-sign">{loading?Loader_spin('warning','Loading'):"Add User"}</Button>
                  </div>
                </Form>
              </Card>
            </div>
        </div>
      </div>
    </div>
  )
}

export default AddUser
