import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. Basix.in. All Rights Reserved.</span>
      <span>Created by: <Link to="https://basix.in/">basix.in</Link></span>
    </div>
  )
}