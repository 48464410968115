import React from 'react';
import Plot from 'react-plotly.js';
import { arrangeRunningCourseData } from '../../helper/Utils';

class RunningCoursesChart extends React.Component {
  render() {
    const {runningCourseData}=this.props;
    let trace1=arrangeRunningCourseData(runningCourseData)
      
    var data = [trace1];

    const layout = {
      title: 'New Batch(Category Wise)',
      xaxis: {
        title: 'Category Type',
      },
      yaxis: {
        title: 'Number of Course',
      },
      height:400
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '100%' }}
      />
    );
  }
}
export default RunningCoursesChart;
