export const all_center_list=()=>{
    const centers=[
        {
            "center_id":"43",
            "center_name":"GS SCORE - DELHI"
        },
        {
            "center_id":"67",
            "center_name":"GS SCORE - BHUBNESHWAR"
        },
        {
            "center_id":"74",
            "center_name":"GS SCORE - JAMMU"
        },
        
    ];
    return centers
}

export const arrange_revenueDashboardData=(revenueData)=>{

    let result=[];
    revenueData.forEach(data => {
        const {name,list}=data;
        let x=[];
        let y=[];
        let eachData={
            type: 'scatter',
            mode: 'lines',
            name:name
        }
        list.forEach(li => {
           let day_with_month_year=li.day_with_month_year
           let total_amount=li.total_amount
           x.push(day_with_month_year)
           y.push(total_amount)
        });
        eachData.x=x
        eachData.y=y
        result.push(eachData)
    });
    return result;
}

export const totalRevenueDashboard=(revenueData)=>{
    let total=0;
    revenueData.forEach(data => {
        const {list}=data;
        list.forEach(li => {
            if(li.total_amount){
                let total_amount=parseInt(li.total_amount)
                total+=total_amount
            }
        });
    });
    // console.log(total.toLocaleString());
    return total;
}

export function randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
export const arrangeRunningCourseData=(runningCourseData)=>{
    // console.log(runningCourseData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    runningCourseData.forEach(li => {
        var a = 100; //r
        var b = 100; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        // console.log(c);
        const {product_category,total_packages}=li;
        x.push(product_category)
        y.push(total_packages)
        color.push(RGBColor)
        
    });
    eachData.x=x
    eachData.y=y
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeEnrolledStudentData=(enrolledStudentData)=>{
    // console.log(enrolledStudentData);
    
    let values=[];
    let labels=[];
    let color=[];

    let eachData={
        type: 'pie',
    }
    
    enrolledStudentData.forEach(li => {
        var a = 100; //r
        var b = 100; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";        
        const {product_category,total_admissions}=li;
        // if(product_category!=='PT Test'){
            
        // }
        values.push(parseInt(total_admissions))
        labels.push(product_category)
        color.push(RGBColor)
    });
    eachData.values=values
    eachData.labels=labels
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeEnrolledStudentModeData=(enrolledStudentModeData)=>{

    let online_value=[];
    let offline_value=[];
    let product_category_list=[];
    
    
    enrolledStudentModeData.forEach(data => {
        const {product_category,online_student,offline_student}=data;
        if(product_category){
            product_category_list.push(product_category);
            online_value.push(online_student)
            offline_value.push(offline_student)
        }
    });
    var onlineData = {
        x: [],
        y: [],
        name: 'ONLINE',
        text: online_value.map(String),
        type: 'bar',
        // marker: {
        //   color: '#506fd9'
        // }
    };

    var offlineData = {
        x: [],
        y: [],
        name: 'OFFLINE',
        text: offline_value.map(String),
        type: 'bar',
        // marker: {
        //   color: '#4059ae'
        // }
    };
    onlineData.y=online_value
    onlineData.x=product_category_list
    offlineData.y=offline_value
    offlineData.x=product_category_list
    
    let data=[onlineData,offlineData];
    return data;
}

export const arrangeTeacherEventData=(teacherEventData)=>{

    let classes_value=[];
    let discussion_value=[];
    let activity_value=[];
    let teacher_list=[];
    
    
    teacherEventData.forEach(data => {
        const {classes,discussion,activity,teacher_name}=data;
        if(teacher_name){
            classes_value.push(classes);
            discussion_value.push(discussion)
            activity_value.push(activity)
            teacher_list.push(teacher_name)
        }
    });
    // console.log(classes_value,discussion_value,activity_value,teacher_list);
    var classesData = {
        x: [],
        y: [],
        name: 'CLASSES',
        text: classes_value.map(String),
        type: 'bar',
    };
    var discussionData = {
        x: [],
        y: [],
        name: 'DISCUSSION',
        text: discussion_value.map(String),
        type: 'bar',
    };
    var activityData = {
        x: [],
        y: [],
        name: 'ACTIVITY',
        text: activity_value.map(String),
        type: 'bar',
    };
    classesData.y=classes_value
    classesData.x=teacher_list
    discussionData.y=discussion_value
    discussionData.x=teacher_list
    activityData.y=activity_value
    activityData.x=teacher_list
    
    let data=[classesData,discussionData,activityData];
    return data;
}

export const arrangeCopyTrackingData=(copyTrackingData)=>{
    console.log(copyTrackingData);
    let x=[];
    let y=[];
    
    let eachData={
        type: 'bar',
    }
    copyTrackingData.forEach(li => {
        
        console.log(li);
        x.push()
        y.push()
    });
    eachData.x=x
    eachData.y=y
    return eachData;
}

export const arrangeCounsellorLeadData=(counsellorLeadData)=>{
    // console.log(counsellorLeadData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    counsellorLeadData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {lead_owner,total_leads}=li;
        
        x.push(lead_owner)
        y.push(parseInt(total_leads))
        color.push(RGBColor)
        
    });
    eachData.x=x
    eachData.y=y
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeSourceLeadData=(sourceLeadData)=>{
    // console.log(sourceLeadData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    sourceLeadData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {lead_source,total_leads}=li;
        if(!lead_source){
            x.push('Blank')
        }else{
            x.push(lead_source)
        }
        y.push(parseInt(total_leads))
        color.push(RGBColor)
    });
    eachData.x=x
    eachData.y=y
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeCounsellorOpprData=(counsellorOpprData)=>{
    // console.log(counsellorOpprData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    counsellorOpprData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {opportunity_owner,total_opportunity}=li;
        
        x.push(opportunity_owner)
        y.push(parseInt(total_opportunity))
        color.push(RGBColor)
        
    });
    eachData.x=x
    eachData.y=y
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeSourceOpprData=(sourceOpprData)=>{
    // console.log(sourceOpprData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    sourceOpprData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {lead_source,total_opportunity}=li;
        if(!lead_source){
            x.push('Blank')
        }else{
            x.push(lead_source)
        }
        y.push(parseInt(total_opportunity))
        color.push(RGBColor)
    });
    eachData.x=x
    eachData.y=y
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeStageOpprData=(stageOpprData)=>{
    // console.log(counsellorOpprData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'bar',
    }
    
    stageOpprData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {stage,total_opportunity}=li;
        if(!stage){
            x.push("Blank")
        }else{
            x.push(stage)
        }
        y.push(parseInt(total_opportunity))
        color.push(RGBColor)
        
    });
    eachData.x=x
    eachData.y=y
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    return eachData;
}

export const arrangeStatusOpprData=(statusOpprData)=>{
    // console.log(statusOpprData);
    let x=[];
    let y=[];
    let color=[];
    let eachData={
        type: 'pie',
    }
    
    statusOpprData.forEach(li => {
        var a = 100; //r
        var b = 169; //g
        var c = randomIntFromInterval(100,256); //b
        var RGBColor = "rgb(" + a + "," + b + "," + c + ")";  
        const {status,total_opportunity}=li;
        if(!status){
            x.push("Blank")
        }else{
            x.push(status)
        }
        y.push(parseInt(total_opportunity))
        color.push(RGBColor)
    });
    eachData.values=y
    eachData.labels=x
    eachData.text=y.map(String)
    eachData.marker={
        color: color
    }
    eachData.hoverinfo='label+percent+value'
    return eachData;
}