import React from 'react';
import Plot from 'react-plotly.js';
import { arrangeEnrolledStudentData } from '../../helper/Utils';

class EnrolledStudentPie extends React.Component {
  render() {
    const {enrolledStudentData}=this.props;    
    let trace1=arrangeEnrolledStudentData(enrolledStudentData)
    var data = [trace1];
    // console.log(data);
    const layout = {
        // title: 'Enrolled Student (Course Type Wise)',
        xaxis: {
            title: 'Course Type',
        },
        yaxis: {
            title: 'Number of Student',
        },
        height:400,
    };

    return (
      <Plot
        data={data}
        layout={layout}
        style={{ width: '100%', height: '400px' }}
      />
    );
  }
}
export default EnrolledStudentPie;
